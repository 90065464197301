<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="400px"
    @keydown.esc="closeDialog"
    @keydown.enter="store"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          saveDialogMode === 'add' ?
            $t('TRANSPORT_DATA.ADD_TRANSPORT_DATA') :
            $t('TRANSPORT_DATA.EDIT_TRANSPORT_DATA')
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
            ref="transportDataForm"
            v-model="validTransportDataForm"
            @submit.prevent
        >
          <v-autocomplete
            :label="$t('TRANSPORT_DATA.COUNTRY_A')"
            v-model="transportData.country_a_id"
            :items="countries"
            item-value="key"
            item-text="name"
            :disabled="countryFieldsDisabled"
            :rules="[validations.required()]"
          />
          <v-autocomplete
            :label="$t('TRANSPORT_DATA.COUNTRY_B')"
            v-model="transportData.country_b_id"
            :items="countries"
            item-value="key"
            item-text="name"
            :disabled="countryFieldsDisabled"
            :rules="[validations.required()]"
          />
          <v-text-field
            type="number"
            step="1"
            min="1"
            :label="$t('TRANSPORT_DATA.DISTANCE')"
            v-model="transportData.distance"
            :rules="[validations.required(), validations.minNumber(1), validations.integer()]"
            :error-messages="error"
          />
          <v-text-field
            type="number"
            min="0.01"
            :label="$t('TRANSPORT_DATA.PRICE')"
            v-model="transportData.price"
            :rules="[validations.required(), validations.minNumber(0.01)]"
            :error-messages="error"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          {{ $t('GENERAL.LABELS.CLOSE') }}
        </v-btn>
        <v-btn color="blue darken-1" text :disabled="isProcessing" @click="store">
          {{ $t('GENERAL.LABELS.SAVE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/views/partials/form/Validations.vue";
import {
  STORE_TRANSPORT_DATA,
  UPDATE_TRANSPORT_DATA
} from '@/store/transportData.module';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    countries: {
      type: Array,
      default: () => []
    },

    transportData: {
      type: Object,
      default: () => ({
        id: null,
        country_a_id: null,
        country_b_id: null,
        distance: null,
        price: null,
      })
    },

    saveDialogMode: {
      type: String,
      default: 'add'
    },

    countryFieldsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isProcessing: false,
      error: "",
      validTransportDataForm: false,
      validations: validations
    };
  },

  watch: {
    value() {
      this.$nextTick(() => {
        this.clearValidation();
      });
    }
  },

  methods: {
    store() {
      this.$refs.transportDataForm.validate();

      if (!this.validTransportDataForm) {
        return;
      }

      const method = this.saveDialogMode === 'add' ?
        STORE_TRANSPORT_DATA :
        UPDATE_TRANSPORT_DATA;

      this.isProcessing = true;

      this.$store.dispatch(method, this.transportData)
        .then(() => {
          this.$emit('transport-data-stored');

          this.closeDialog();
        }).finally(() => {
          this.isProcessing = false;
        });
    },

    closeDialog() {
      this.$emit('input', false);
    },

    clearValidation() {
      this.error = "";
      this.$refs.transportDataForm.resetValidation();
    }
  },
};
</script>

<style>
</style>