<template>
  <div>
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ $t('TRANSPORT_DATA.TITLE') }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="buttons-tab">
              <v-btn
                class="mr-4 primary"
                :disabled="isProcessing"
                @click="handleCreateTransportDataClick()"
              >
                {{ $t('GENERAL.LABELS.CREATE_BUTTON') }}
              </v-btn>
            </div>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" offset="1" sm="10">
                      <Table
                        ref="transport-data-table"
                        height="500"
                        :name="'transport-data-table-' + $route.params.id"
                        :headers="headers"
                        :server-data-options="{
                          method: tableItemsMethod
                        }"
                        :items-per-page="100"
                        :loading="isProcessing"
                        dense
                        fixed-header
                        multi-sort
                        filterable
                        @fetched-server-data="lastFetchParams = $event"
                      >
                        <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                          <span
                            :key="index"
                            v-if="header.value === 'options'"
                          >
                            <v-btn
                              icon
                              color="primary"
                              @click="handleUpdateTransportDataClick(item.item)"
                            >
                              <v-icon dark>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              color="red"
                              @click="handleDeleteClick(item.item.id)"
                            >
                              <v-icon dark>
                                mdi-trash-can
                              </v-icon>
                            </v-btn>
                          </span>
                          <template v-else>
                            {{ item.value }}
                          </template>
                        </template>
                      </Table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <AddTransportDataDialog
            v-model="saveDialog"
            :countries="countries"
            :transport-data="transportData"
            :save-dialog-mode="saveDialogMode"
            @transport-data-stored="$refs['transport-data-table'].fetchServerData()"
          />
          <Confirm
            v-model="deleteConfirm"
            :content="$t('GENERAL.DELETE_CONFIRM')"
            @confirm="deleteTransportData"
            @decline="deleteConfirm = false"
          />
        </v-card>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import { GET_SELECTS_DATA } from "@/store/selectsData.module";
import AddTransportDataDialog from "@/views/pages/transportData/partials/AddTransportDataDialog.vue";
import {
  GET_TRANSPORT_DATAS,
  DELETE_TRANSPORT_DATA,
} from '@/store/transportData.module';

export default {
  components: { KTPortlet, Table, Confirm, AddTransportDataDialog },

  data() {
    return {
      tab: "tab-1",
      isProcessing: false,
      countries: [],
      transportData: {
        id: null,
        country_a_id: null,
        country_b_id: null,
        distance: null,
        price: null,
      },
      tableItemsMethod: GET_TRANSPORT_DATAS,
      saveDialog: false,
      saveDialogMode: 'add',
      deleteConfirm: false,
      transportDataForDelete: null,
      headers: [
        {
          text: this.$t('TRANSPORT_DATA.COUNTRY_A'),
          value: 'country_a.label',
          align: 'center',
          width: '20%'
        },
        {
          text: this.$t('TRANSPORT_DATA.COUNTRY_B'),
          value: 'country_b.label',
          align: 'center',
          width: '20%'
        },
        {
          text: this.$t('TRANSPORT_DATA.DISTANCE'),
          value: 'distance',
          align: 'center',
          width: '20%'
        },
        {
          text: this.$t('TRANSPORT_DATA.PRICE'),
          value: 'price',
          align: 'center',
          width: '20%'
        },
        {
          text: this.$t('GENERAL.TABLE.OPTIONS_COLUMN'),
          value: 'options',
          align: 'center',
          width: '20%',
        },
      ]
    };
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    getCountries() {
      return this.$store.dispatch(GET_SELECTS_DATA, [
        'countries'
      ])
        .then((response) => {
          this.countries = response['countries'];
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    closeSaveDialog() {
      this.saveDialog = false;

      this.clearTransportData();
    },

    handleCreateTransportDataClick() {
      this.saveDialogMode = 'add';
      this.saveDialog = true;
    },

    handleUpdateTransportDataClick(transportData) {
      this.transportData.id = transportData.id;
      this.transportData.country_a_id = transportData.country_a.id;
      this.transportData.country_b_id = transportData.country_b.id;
      this.transportData.distance = transportData.distance;
      this.transportData.price = transportData.price;

      this.saveDialogMode = 'edit';
      this.saveDialog = true;
    },

    clearTransportData() {
        this.transportData.id = null;
        this.transportData.country_a_id = null;
        this.transportData.country_b_id = null;
        this.transportData.distance = null;
        this.transportData.price = null;
    },

    handleDeleteClick(id) {
      this.transportDataForDelete = id;
			this.deleteConfirm = true;
    },

    deleteTransportData() {
      this.isProcessing = true;

      this.$store
        .dispatch(DELETE_TRANSPORT_DATA, this.transportDataForDelete)
        .then(() => {
          this.$refs['transport-data-table'].fetchServerData();
          this.isProcessing = false;
        })
        .catch((response) => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }
  },
};
</script>

<style>
</style>